<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
          <div class="col-lg-12"> -->
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">
          Quality Control PPB Kementerian Lembaga
        </h3>
        <div class="card-toolbar"></div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <!--begin::Item-->
        <div class="mb-12">
          <!--begin::Content-->
          <div>
            <div style="overflow-x: auto">
              <table
                id="example"
                class="table table-striped table-bordered"
                style="border-collapse: collapse; width: 100%"
              >
                <thead>
                  <tr>
                    <th rowspan="2">No</th>
                    <th rowspan="2">Nama KL</th>
                    <th style="text-align: center" colspan="2">
                      Penilaian Tahun 2022
                    </th>

                    <th style="text-align: center" colspan="2">
                      Penilaian Tahun 2023
                    </th>

                    <th style="text-align: center" colspan="2">
                      Penilaian Tahun 2024
                    </th>

                    <th style="text-align: center" rowspan="2">
                      Perubahan Predikat 2022 - 2023
                    </th>
                    <th style="text-align: center" rowspan="2">
                      Perubahan Predikat 2023 - 2024
                    </th>
                    <th style="text-align: center">Quality Control</th>
                    <th rowspan="2">Nilai Akhir</th>

                    <th style="text-align: center">File BA QC</th>
                    <th style="text-align: center">Aksi</th>
                  </tr>
                  <tr>
                    <th style="text-align: center">PM PPB</th>
                    <th style="text-align: center">Nilai Akhir</th>

                    <th style="text-align: center">PM PPB</th>
                    <th style="text-align: center">Nilai Akhir</th>

                    <th style="text-align: center">PM PPB</th>
                    <th style="text-align: center">Nilai Akhir</th>

                    <th style="text-align: center">PM PPB</th>
                    <th style="text-align: center">PM PPB</th>
                    <th style="text-align: center">PM PPB</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row,index) in totskor" :key="index">
                    <td>{{index+1}}</td>
                    <td>{{ row.nama_kl }}</td>
                    <td>
                      <div style="width: 130px">
                        {{ format_desimal(row.data_2023.total_ppb_past) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 130px">
                        {{ format_desimal(row.data_2023.total_akhir_past) }}
                      </div>
                    </td>

                    <td>
                      <div style="width: 130px">
                        {{ format_desimal(row.total_ppb_past) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 130px">
                        {{ format_desimal(row.total_akhir_past) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 130px">
                        {{ format_desimal(row.total_ppb_curr) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 130px">
                        {{ format_desimal(row.total_akhir_curr) }}
                      </div>
                    </td>
                    <td>
                      <b-badge
                        v-if="row.data_2023.status_perubahan == 'Naik'"
                        variant="success"
                        >{{ row.data_2023.status_perubahan }}</b-badge
                      >
                      <b-badge
                        v-else-if="row.data_2023.status_perubahan == 'Sama'"
                        class="text-white"
                        style="background-color: dimgray"
                        >{{ row.data_2023.status_perubahan }}</b-badge
                      >
                      <b-badge
                        v-else-if="row.data_2023.status_perubahan == 'Turun'"
                        variant="danger"
                        >{{ row.data_2023.status_perubahan }}</b-badge
                      >
                    </td>
                    <td>
                      <b-badge
                        v-if="row.status_perubahan == 'Naik'"
                        variant="success"
                        >{{ row.status_perubahan }}</b-badge
                      >
                      <b-badge
                        v-else-if="row.status_perubahan == 'Sama'"
                        class="text-white"
                        style="background-color: dimgray"
                        >{{ row.status_perubahan }}</b-badge
                      >
                      <b-badge
                        v-else-if="row.status_perubahan == 'Turun'"
                        variant="danger"
                        >{{ row.status_perubahan }}</b-badge
                      >
                    </td>
                    <td>
                      <div style="width: 130px">
                        {{ format_desimal(row.total_ppb_qc) }}
                      </div>
                    </td>
                    <td>
                      <div style="width: 130px">
                        {{ format_desimal(row.total_akhir_qc) }}
                      </div>
                    </td>

                    <td style="text-align: center">
                      <div
                        style="width: 120px"
                        v-if="row.file_ba_ppb == null || row.file_ba_ppb === ''"
                      ></div>
                      <div v-else>
                        <a
                          :href="row.file_ba_ppb"
                          target="_blank"
                          class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"
                          ><i class="fa fa-download" aria-hidden="true"
                        /></a>
                      </div>
                    </td>
                    <td>
                      <b-button
                        variant="primary"
                        @click="qualityControl(row.idkl, row.nama_kl)"
                        v-if="
                          user.id_role === 'qc' &&
                          (row.file_ba_ppb == null || row.file_ba_ppb == '')
                        "
                        >QC PPB KL</b-button
                      >
                      <b-button
                        variant="primary"
                        @click="qualityControl(row.idkl, row.nama_kl)"
                        v-else-if="
                          user.id_role === 'qc' &&
                          (row.file_ba_ppb != null || row.file_ba_ppb != '')
                        "
                        disabled
                        >QC PPB KL</b-button
                      >
                      <b-button
                        variant="primary"
                        @click="qualityControl(row.idkl, row.nama_kl)"
                        v-else-if="
                          user.id_role === 'pendamping_qc' ||
                          user.id_role === 'super_user' ||
                          user.id_role === 'helpdesk' || user.id_role === 'dpb'
                        "
                        >Lihat</b-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Item-->
      </div>
      <!--end: Card Body-->
    </div>
  </div>
  <!-- </div>
    </div> -->
</template>
<style>
div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<style lang="">
</style>
<script src="https://code.jquery.com/jquery-3.5.1.js"></script>
  <script src="https://cdn.datatables.net/1.10.24/js/jquery.dataTables.min.js"></script>
  <script src="https://cdn.datatables.net/buttons/1.7.0/js/dataTables.buttons.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/pdfmake.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/vfs_fonts.js"></script>
  <script src="https://cdn.datatables.net/buttons/1.7.0/js/buttons.print.min.js"></script>
  <script src="https://cdn.datatables.net/buttons/1.7.0/js/buttons.html5.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.min.js"></script>
  <script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
export default {
  data() {
    return {
      test: "hai",
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      totskor: [],
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Quality Control" }]);
    this.loaddata();
  },
  methods: {
    qualityControl(idptsp, nama_wilayah) {
      // localStorage.setItem("idptsp", idptsp);
      // localStorage.setItem("nama_wilayah", nama_wilayah);
      localStorage.setItem("jenis", "kl");
      if (this.user.id_role === "qc") {
        this.$router.push("/qualitycontrol/qc-ppbkl/"+idptsp);
        // this.$router.push({ name: "qc-ppbkl" });
      } else if (
        this.user.id_role === "pendamping_qc" ||
        this.user.id_role === "super_user" ||
        this.user.id_role === "helpdesk" || this.user.id_role === "dpb"
      ) {
        this.$router.push("/qualitycontrol/detail-qc-ppbkl/"+idptsp);

      }
    },
    loaddata() {
      if (this.user.id_role === "qc") {
        axios
          .get(
            this.url +
              "/rekapitulasi_qc_kl?filter=tahun,=,2024;perlu_qc,=,true;id_tim_qc,=," +
              this.user.id,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.totskor = response.data.data;
            this.initDatatable();
          })
          .catch((error) => {
            return error;
          });
      } else if (this.user.id_role === "pendamping_qc") {
        axios
          .get(
            this.url +
              "/rekapitulasi_qc_kl?filter=tahun,=,2024;perlu_qc,=,true;id_pendamping_qc,=," +
              this.user.id,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.totskor = response.data.data;
            this.initDatatable();
          })
          .catch((error) => {
            return error;
          });
      } else if (
        this.user.id_role === "super_user" ||
        this.user.id_role === "helpdesk" || this.user.id_role === "dpb"
      ) {
        axios
          .get(this.url + "/rekapitulasi_qc_kl?filter=tahun,=,2024;perlu_qc,=,true", {
            headers: {
              xth: this.token,
            },
          })
          .then((response) => {
            this.totskor = response.data.data;
            this.initDatatable();
          })
          .catch((error) => {
            return error;
          });
      }
    },
    initDatatable() {
      setTimeout(() => {
        var t = $("#example").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          scrollX: true,
          fixedColumns: {
            left: 2,
          },
          dom: "Bfrtip",
          // "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          //     //debugger;
          //     var index = iDisplayIndexFull + 1;
          //     $("td:first", nRow).html(index);
          //     return nRow;
          //   },
          //   columnDefs: [{
          //     targets: 0,
          //     render: function(data, type, row, meta) {
          //     console.log(meta.row);
          //     console.log(type == 'export'? meta.row : data);
          //       return type == 'export'? meta.row + 1 : data;
          //     }
          // }],
          buttons: [
            {
              extend: "excel",
              text: '<i class="fa fa-file-excel-o"></i>&nbsp;&nbsp;Excel',
              className: "btn btn-success",
              title: "NILAI QUALITY CONTROL KL KEMENTERIAN INVESTASI/BKPM",
              exportOptions: {
                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8,9,10,11],
                orthogonal: "export",
                format: {
                  header: function (data, columnIdx) {
                    if (columnIdx == 2 || columnIdx == 3) {
                      return data + " Tahun 2022";
                    } else if (columnIdx == 4 || columnIdx == 5) {
                      return data + " Tahun 2023";
                    } else if (columnIdx == 6 || columnIdx == 7) {
                      return data + " Tahun 2024";
                    } else if (columnIdx == 10 || columnIdx == 11) {
                      return data + " Hasil Quality Control";
                    } else {
                      return data;
                    }
                  },
                  body: function (data, row, column, node) {
                    data = $("<p>" + data + "</p>").text();
                    return data.replace(",", ".");
                  },
                },
              },
              filename: function () {
                var today = new Date();
                // var n = d.getTime();
                var date =
                  today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate();

                return "Nilai Quality Control PPB KL " + date;
              },
            },
            // {
            //   extend: 'pdf',
            //     text : 'PDF',
            //     title: 'NILAI QUALITY CONTROL KL KEMENTERIAN INVESTASI/BKPM',
            //     exportOptions: {
            //         columns: [ 0, 1, 2, 3],
            //         orthogonal: 'export',
            //     },
            //     filename: function(){
            //         var today = new Date();
            //         // var n = d.getTime();
            //         var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

            //         return 'Nilai Quality Control PPB KL ' + date;
            //     },
            // }
          ],
          lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, "All"],
          ],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        // t.on( 'order.dt search.dt', function () {
        // t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
        //     cell.innerHTML = i+1;
        // } );
        // } ).draw();
        
      }, 300);
    },
    format_desimal(value) {
      if (value) {
        return value.replace(".", ",");
      }
    },
    urlppb(kode_wilayah) {
      let routeData = this.$router.resolve({
        path: `/rekapitulasi/detailppbkl/${kode_wilayah}`,
      });
      window.open(routeData.href, "_blank");
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
  created() {
    //   this.getuser(this.user)
    // this.getlocaldata()
  },
};
</script>
  